import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/cultural-hotspots-san-diego-neighborhoods"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Cultural Hotspots_ Exploring San Diego's Diverse Neighborhoods (Premium).jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Looking for a neighborhood that's warm, inclusive, and historically rich? San Diego
                offers plenty of choices. As long-time residents with deep community roots, we've
                come to appreciate several areas for these exact reasons. Here are four diverse
                cultural neighborhoods you should know about.
              </CommonParagraph>
              <Heading type="h2">Hillcrest</Heading>
              <CommonParagraph>
                Known for its diversity, laid-back vibes, and pedestrian-friendly streets, Hillcrest
                has been an LGBTQ+ hub since the 1970s. Here, you’ll find a collection of coffee
                shops, bars, restaurants, nightclubs, and boutiques as unique as the neighborhood’s
                welcoming residents.{" "}
              </CommonParagraph>
              <CommonParagraph>
                Located just a few miles north of downtown, the{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/hillcrest-homes-for-sale"
                  text="Hillcrest"
                />{" "}
                cultural neighborhood is the starting point for San Diego’s LGBTQ+ annual mile-long
                parade. It’s also home to a well-curated collection of vintage clothing shops,
                bookstores, theaters, and the LGBTQ+ Community Center, an anchor institution that
                has served the community for over 50 years.
              </CommonParagraph>
              <CommonParagraph>
                In terms of real estate, here’s the state of the market at a glance:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>As of June 2024, the median sale price is $839,500, up 1% from last year</li>
                <li>The average home sells for about 2% below list price within 53 days</li>
                <li>Competitive homes sell at asking price and go pending in 11 days</li>
              </ul>
              <Heading type="h2">City Heights </Heading>
              <CommonParagraph>
                Often overlooked by tourists, San Diego locals have long called this under-the-radar
                neighborhood one of the city’s best (or worst) kept secrets. With a San Diego
                diversity score of 74 out of 100,{" "}
                <BlogLink
                  url="https://selbysellssd.com/welcome-to-city-heights-san-diego-s-hidden-gem"
                  text="City Heights’s population is"
                />
                :
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>61% Hispanic</li>
                <li>15.4% White</li>
                <li>11% Asian</li>
              </ul>
              <CommonParagraph>
                This cultural mix is appealing, especially to foodies. Whether you crave Chinese,
                Cambodian, Nigerian, Italian, Mexican, Vietnamese, Filipino, or American soul food,
                you can find it here.
              </CommonParagraph>
              <CommonParagraph>
                And in a city where the average home price is roughly $1 million, City Heights is
                relatively affordable.
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>As of June 2024, the median sale price is $712,750, up 30.8% from last year</li>
                <li>The average home sells for around the list price within 14 days</li>
                <li>Competitive homes sell at 4% above asking price and go pending in 7 days</li>
              </ul>
              <Heading type="h2">Barrio Logan</Heading>
              <CommonParagraph>
                Step into Barrio Logan, San Diego's oldest Mexican-American community. This isn't
                just a neighborhood—it's a living, breathing work of art, home to the largest
                collection of outdoor murals in the United States. Here, you'll find more than 100
                paintings spread across seven acres of Chicano Park, a space designated as a
                National Landmark in 2016.
              </CommonParagraph>
              <CommonParagraph>
                This section of San Diego is also home to one of our favorite restaurants, Las
                Cuatro Milpas, the second-oldest restaurant in the city. We should also mention the
                La Vuelta Car Cruise, which happens every other Wednesday during the summer, and the
                Barrio Art Crawl, a free monthly event that takes place every second Saturday of the
                month.
              </CommonParagraph>
              <CommonParagraph>
                As of June 2024, the median sale price is $825,000, up 66.7% from last year.
                Selection is slim, unfortunately, as there are only four homes currently on the
                market.
              </CommonParagraph>
              <Heading type="h2">Mira Mesa</Heading>
              <CommonParagraph>
                Just east of Sorrento Valley, the{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/mira-mesa-homes-for-sale"
                  text="Mira Mesa"
                />{" "}
                cultural neighborhood flies under the radar of many visitors. Diversity in San Diego
                is one of this community's defining features. With the population being 51% Asian,
                25% White, and 15% Hispanic, Mira Mesa is a true melting pot.
              </CommonParagraph>
              <CommonParagraph>
                This community is home to numerous award-winning schools, making it an attractive
                option for families. It also has a vibrant culinary scene; from Filipino bakeries to
                Korean BBQ joints and craft breweries, the food scene truly reflects Mira Mesa's
                diverse population.
              </CommonParagraph>
              <CommonParagraph>
                In terms of real estate, here's the state of the market in Mira Mesa:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>As of June 2024, the median sale price is $993,000, up 6.6% from last year</li>
                <li>The average home sells for around 2% above asking price in about 11 days</li>
                <li>
                  Competitive homes sell at about 6% above asking price and go pending in 7 days
                </li>
              </ul>
              <Heading type="h2">We’ll Help You Find Your Place in San Diego</Heading>
              <CommonParagraph>
                Looking to set down roots in a diverse community that welcomes people from all walks
                of life? The Selby Team can help you find the perfect community. Whether you’re
                looking in Mira Mesa, City Heights, or anything in between, we can help welcome you
                home. <ContactSlideoutLink text="Contact us" /> today!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
